import React from "react"
import styled from "styled-components"
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "gatsby-plugin-intl"

import AppStoreBadgeEN from "../../../assets/third-parties/AppStoreBadgeEN.svg"
import AppStoreBadgeIT from "../../../assets/third-parties/AppStoreBadgeIT.svg"
import GooglePlayBadgeEN from "../../../assets/third-parties/GooglePlayBadgeEN.svg"
import GooglePlayBadgeIT from "../../../assets/third-parties/GooglePlayBadgeIT.svg"
import WineTasting from "../../../assets/the-cocktail-zone/WineTasting.svg"

import Footer from "../../../components/Footer"
import Layout from "../../../components/Layout"
import Meta from "../../../components/Meta"
import Navbar from "../../../components/Navbar"
import devices from "../../../helpers/devices"
import { H1 } from "../../../components/atoms/ResTypography"
import { PageProps } from "../../../helpers/props"

const StyledHero = styled.div`
  font-size: 1.25rem;

  .features {
    margin-bottom: 2rem;

    p {
      margin-bottom: 1rem;
    }

    div {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      p {
        margin-bottom: 0.5rem;
        font-weight: 600;
      }

      ul {
        margin-left: 1.25rem;
        list-style-type: circle;
      }
    }
  }

  .download-app {
    text-align: left;

    a {
      &:not(:last-child) {
        display: inline;
        margin: 0 0.5rem 0 0;
      }

      img {
        height: 3.25rem;
      }
    }

    @media ${devices.mobile} {
      text-align: center;

      a:not(:last-child) {
        display: block;
        margin: 0 0 0.25rem 0;
      }
    }
  }
`

const CocktailZoneIndexPage = ({ location }: PageProps) => {
  const intl = useIntl()
  const title = "Cocktail Zone"

  return (
    <Layout>
      <Meta
        description={intl.formatMessage({ id: "prod_tcz_meta_description" })}
        image={{
          height: 630,
          src: "https://thecocktail.zone/images/meta/og_image_v2.png",
          width: 1200,
        }}
        pathname={location.pathname}
        title={title}
      />
      <Navbar isFixedTop={false} />
      <section className="section" id="hero">
        <div className="container">
          <div className="columns">
            <div className="column">
              <H1 className="title" style={{ color: "var(--color-tcz-primary)" }}>
                {title}
              </H1>
            </div>
          </div>
          <div className="columns">
            <div className="column is-two-thirds-tablet is-three-fifths-desktop">
              <StyledHero>
                <div className="features">
                  <FormattedHTMLMessage id="h_tcz_hp1" tagName="p" />
                  <div>
                    <FormattedMessage id="h_tcz_hp2" tagName="p" />
                    <ul>
                      <li>
                        <FormattedMessage id="h_tcz_hp2_ul_li1" />
                      </li>
                      <li>
                        <FormattedMessage id="h_tcz_hp2_ul_li2" />
                      </li>
                      <li>
                        <FormattedMessage id="h_tcz_hp2_ul_li3" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="margin-bottom-2rem">
                  <FormattedMessage id="h_tcz_hp3" tagName="p" />
                </div>
                <div className="margin-bottom-2rem">
                  <div className="download-app">
                    <a
                      href="https://apps.apple.com/app/apple-store/id1576513084?pt=117829052&ct=simpa.maxdelia.it&mt=8"
                      rel="noreferrer"
                      target="_blank"
                      title={intl.formatMessage({ id: "download_on_the_app_store" })}
                    >
                      <img
                        alt={intl.formatMessage({ id: "download_on_the_app_store" })}
                        src={intl.locale === "en" ? AppStoreBadgeEN : AppStoreBadgeIT}
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=co.simpa.thecocktailzone&utm_source=website&utm_medium=pr&utm_campaign=simpa.maxdelia.it"
                      rel="noreferrer"
                      target="_blank"
                      title={intl.formatMessage({ id: "get_it_on_google_play" })}
                    >
                      <img
                        alt={intl.formatMessage({ id: "get_it_on_google_play" })}
                        src={intl.locale === "en" ? GooglePlayBadgeEN : GooglePlayBadgeIT}
                      />
                    </a>
                  </div>
                </div>
              </StyledHero>
            </div>
            <div className="column is-5 is-hidden-mobile">
              <img alt="" className="il" src={WineTasting} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default CocktailZoneIndexPage
